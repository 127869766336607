<template>
    <div>
        <van-calendar v-model="show" :type="calendarType" @confirm="onConfirmDate" :min-date="new Date(1990, 0, 1)"	:max-date="new Date()" :max-range="90"/>
    </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
export default {
    mounted() {
       
    },
    data() {
        return {
            show:false,
            calendarType:'range',
        }
    },
    watch: {
       
    },
    methods: {
        formatDate(date) {
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2,'0')}-${date.getDate().toString().padStart(2,'0')}`;
        },
        chooseDateType(){
            Dialog.confirm({
                title: '温馨提示',
                message: '请先选择日期筛选类型',
                confirmButtonText:'多日筛选',
                cancelButtonText:'单日筛选',
            }).then(() => {
            // on confirm
                this.calendarType="range"
                this.show=true
            }).catch(() => {
            // on cancel
                this.calendarType="single"
                this.show=true
            });
        },
        onConfirmDate(date) {
            let start, end
            if(this.calendarType=="range"){
                start = date[0];
                end = date[1];
            }else{
                start=end=date
            }
            this.show = false;
            let dateResult={}
            dateResult.startDate=this.formatDate(start)
            dateResult.endDate=this.formatDate(end)
            this.$emit('onConfirmDate', dateResult)
        },
        
    },
}
</script>