<template>
    <div>

        <Head :title="'拆解生产看板'" :isPopup="true" @closePopup="$emit('closePopup')" />
        <div class="content" :style="{ paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }">
            <div class="content-p" :style="{margin:'15px 0px',borderRadius:'5px'}">
                <van-row gutter="0" @click="chooseDate" :style="{padding:'10px 0px'}">
                    <van-col span="24" :style="{textAlign:'center'}">
                        <span><van-icon name="clock-o" :style="{color:'black',paddingRight:'5px',fontWeight:'bolder'}"/></span>
                        <span :style="{color:'red',fontWeight:'bolder'}">统计日期：</span>
                        <span :style="{color:'red',fontWeight:'bolder'}">{{date}}</span>
                    </van-col>
                </van-row>
            </div>
            <div :style="{textAlign:'center',fontWeight:'bolder',marginBottom:'15px'}">
                分配拆解总量： {{this.total}}
            </div>
            <van-grid v-if="tabledata.length>0" :border="false" :style="{border:'1px solid black',borderBottomWidth:'0px',fontWeight:'bolder',color:'red'}">
                <van-grid-item class="tr-border-right" :style="{flexBasis:'30%'}">分配日期</van-grid-item>
                <van-grid-item class="tr-border-right" :style="{flexBasis:'30%'}">分配拆解量</van-grid-item>
                <van-grid-item class="" :style="{flexBasis:'40%'}">状态分类</van-grid-item>
            </van-grid>
            <div :style="{marginBottom:'30px'}"> 
                <van-grid :border="false" v-for="(item,index) in tabledata" :key="index" :style="{border:'1px solid black',borderBottomWidth:index<(tabledata.length-1)?'0px':'1px'}">
                    <van-grid-item class="tr-border-right" :style="{flexBasis:'30%'}">{{item.createDate}}</van-grid-item>
                    <van-grid-item class="tr-border-right" :style="{flexBasis:'30%'}">{{item.account}}</van-grid-item>
                    <van-grid-item class="" :style="{flexBasis:'40%'}">
                        <div v-for="(it,idx) in item.data" :key="idx" :style="{width:'100%',margin:'10px 0px'}">
                            <div :style="{width:'50%',float:'left',textAlign:'left'}">
                                <!-- 是否合格 0不合格 1合格 2待审核 3待修改 8进场登记中 小于9进入车辆总库(入场是否合格) -->
                                <span v-if="it.isDismantle===0">未完成</span>
                                <span v-if="it.isDismantle===1">已完成</span>
                            </div>
                            <div :style="{width:'50%',float:'left',textAlign:'right'}">
                                {{it.account}}
                            </div>
                        </div>

                    </van-grid-item>
                </van-grid>
            </div>            
            <div v-show="false" id="mainechart" :style="{ height: '500px' }"></div>
        </div>
        <dateCalendar ref="dateCalendarRef" @onConfirmDate="onConfirmDate"  />
    </div>
</template>

<script>
import Head from '@/components/Head'
import { reusablePartsTask, recordsFind } from '@/api/gong'
import { Toast, Dialog } from 'vant'
import { is_post,post } from '@/Http/api'
import { getNearly7Day } from '@/utils/formatDate'
import dateCalendar from './components/dateCalendar'
export default {
    components: {
        Head,dateCalendar
    },
    mounted() {
        let dateWeek=(getNearly7Day())
        console.log("dateWeek",dateWeek)
        this.formData.startDate=dateWeek[dateWeek.length-1];
        this.formData.endDate=dateWeek[0];
        this.date=this.formData.startDate+' 至 '+this.formData.endDate
        this.getinfo()
    },
    data() {
        return {
            tabledata:[],
            date: '2022-11-16 至 2022-11-18',
            show: false,
            total:0,
            formData:{
                startDate:'0000-00-00',
                endDate:'0000-00-00',
            }
        }
    },
    watch: {
        wximagelocalids: {
            handler(newValue, oldValue) {
                if (newValue.length > 0) {
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                } else {
                    this.toast.clear();
                }
            },
            deep: true,
        }
    },
    methods: {
        chooseDate(){
            this.$nextTick(() => {
                this.$refs.dateCalendarRef.chooseDateType()
            })
        },
        onConfirmDate(date) {
            this.formData.startDate=date.startDate;
            this.formData.endDate=date.endDate;
            this.date = `${this.formData.startDate} 至 ${this.formData.endDate}`;
            this.getinfo()
        },
        async getinfo() {
            let res = await post("/index.php/index/end_of_life_vehicles/cars_disassembly",this.formData).then(res => res)
            this.tabledata=res.data.data.list
            this.total=res.data.data.total                
        },
        
    },
}
</script>

<style lang="scss" scoped>
.content {
    margin-top: 50px;
    .content-p{
        padding: 10px;
        background: -webkit-gradient(linear,0 0,0 100%,from(#16cffb),to(#99eaff));     
    }
    .btn-bom {
        padding: 0 10px;
        margin-top: 50px;

        .content-btn {
            margin: 10px 0;
        }
    }
}

.warper-tit {
    position: relative;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #eee;

    .van-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.title {
    font-weight: bold;
    font-size: 22px;
    padding: 10px;
}

.forms {
    padding: 20px;
    position: relative;

    .van-icon {
        position: absolute;
        right: 10px;
        top: 0px;
    }
}

.isSubmit {
    margin-top: 1rem;
    text-align: center;

    .van-button-p {
        margin: 0 10px;
    }
}

.upimg {
    padding: 0 12px;

    .uploaderImg {
        display: inline-block;
        width: 100px;
        height: 100px;
        border: 1px solid #eee;
        position: relative;

        .iconz {
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}

.vanImage {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;

    .iconX {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}

.tr-border-right {
    border-right:1px solid black;
}



</style>