<template> 
    <div>
        <div class="header">
             <span @click="gouot"></span>
             <span>业务看板</span>
             <span @click="islogout">退出</span>
        </div>
        <div class="content" >
            <van-grid :border="true" :column-num="3" :gutter="10" :style="{marginTop:'30px'}">
                <van-grid-item v-for="value in listS" :key="value.id"  @click="openPopup(value.id)" class="grid-item" >
                    <van-icon class-prefix="iconfont" :name="value.io" :style="{fontSize:'300%',paddingBottom:'10px'}"/>
                    <div >{{value.name}}</div>
                </van-grid-item>
            </van-grid>
            <van-grid :border="true" :column-num="2" :gutter="10">
                <van-grid-item v-for="value in listS2" :key="value.id"  @click="openPopup(value.id)" class="grid-item" >
                    <van-icon class-prefix="iconfont" :name="value.io" :style="{fontSize:'300%',paddingBottom:'10px'}"/>
                    <div >{{value.name}}</div>
                </van-grid-item>
            </van-grid>
        </div>

        <van-popup
            v-model="showpopup"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
            <vehicleProcurement v-if="showid == 1" ref="vehicleProcurementRef" @closePopup="closePopup"  />
            <vehicleScrap v-if="showid == 2" ref="vehicleScrapRef" @closePopup="closePopup"  />
            <vehicleDisassembly v-if="showid == 3" ref="vehicleDisassemblyRef" @closePopup="closePopup"  />
            <vehicleDisassemblyParts v-if="showid == 4" ref="vehicleDisassemblyPartsRef" @closePopup="closePopup"  />
            <vehicleDisassemblyBulk v-if="showid == 5" ref="vehicleDisassemblyBulkRef" @closePopup="closePopup"  />
            <vehicleDisassemblyWaste v-if="showid == 6" ref="vehicleDisassemblyWasteRef" @closePopup="closePopup"  />
            <yingfu v-if="showid == 7" ref="yingfuRef" @closePopup="closePopup"  />
            <yingshou v-if="showid == 8" ref="yingshouRef" @closePopup="closePopup"  />
            
        </van-popup>
        
    </div>
</template>

<script>
import Vue from 'vue';
import { Lazyload ,Dialog} from 'vant';
import "#/css/iconfont/iconfont.css"
import vehicleProcurement from './components/vehicleProcurement'
import vehicleScrap from './components/vehicleScrap'
import vehicleDisassembly from './components/vehicleDisassembly'
import vehicleDisassemblyParts from './components/vehicleDisassemblyParts'
import vehicleDisassemblyBulk from './components/vehicleDisassemblyBulk'
import vehicleDisassemblyWaste from './components/vehicleDisassemblyWaste'
import yingfu from './components/yingfu'
import yingshou from './components/yingshou'
Vue.use(Lazyload);
    export default {
        components: {
            vehicleProcurement,vehicleScrap,vehicleDisassembly,vehicleDisassemblyParts,vehicleDisassemblyBulk,vehicleDisassemblyWaste,yingfu,yingshou
        },
        data() {
            return{
                listS:[
                    {id:1,name:'车辆采购',io:'buhuanhangshizheng'},
                    {id:2,name:'报废手续',io:'cheliangjiance'},
                    {id:3,name:'拆解生产',io:'a-productivetask'},
                    {id:4,name:'配件产出',io:'xinxinicon'},
                    {id:5,name:'大宗产出',io:'gaojijibao'},
                    {id:6,name:'危废产出',io:'weixian'},
                ],
                listS2:[
                    {id:7,name:'应付账款',io:'huijiyingfuguanli'},
                    {id:8,name:'应收账款',io:'huijiyingshouguanli'},
                    
                ],
                showpopup:false,
                showid:"",
            }
        },
        
        computed: {
            shopName() {
                return window.localStorage.getItem('shopName') || ''
            },
            userName() {
                return window.localStorage.getItem('userName') || ''
            },
            realName() {
                return window.localStorage.getItem('realName') || ''
            },
        },
        methods: {
            openPopup(id){
                this.showpopup=true
                this.showid=id
            },
            closePopup(){
                this.showpopup=false
                this.showid=""
            },
            gouot() {
                this.$router.push('/guide')
            },
           
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.content{
    padding-top: 50px;
    .content-p{
        padding: 10px;
        background: -webkit-gradient(linear,0 0,0 100%,from(#16cffb),to(#99eaff));     
    }
    .content-title{
        font-weight: bold;
    }
    .content-btn{
        margin: 10px 0;
    }
    .btn-bom{
        padding: 0 10px;
        margin-top: 50px;
    }
    .grid-item{
    height: 3rem;
    /deep/.van-grid-item__text {
        font-size: 18px;
    }
}
}
</style>